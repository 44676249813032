// App.js
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Login from './app/screens/login';
import Lists from './app/screens/lists';
import Details from './app/screens/details';
import SignupScreen from './app/screens/SignupScreen';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { FIREBASE_AUTH } from './firebaseconfig';





const Stack = createStackNavigator();
const InsideStack = createStackNavigator();

function InsideLayout() {
  return (
    <InsideStack.Navigator>
      <InsideStack.Screen name="My todos" component={Lists} />
      <InsideStack.Screen name="details" component={Details} />
    </InsideStack.Navigator>
  );
}

const App = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(FIREBASE_AUTH, (user) => {
      console.log('user', user);
      setUser(user);
    });
  }, [] )

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName={user ? 'Inside' : 'Login'}>
        {user ? (
          <Stack.Screen name='Inside' component={InsideLayout} options={{ headerShown: false }} />
        ) : (
          <Stack.Screen name='Login' component={Login} options={{ headerShown: false }} />
        )}
                <Stack.Screen name="SignupScreen" component={SignupScreen} options={{ headerShown: true }}/>

      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
