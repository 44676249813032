import { View, Text, TextInput, Button, StyleSheet, ActivityIndicator, KeyboardAvoidingView } from "react-native";
import React, { useState } from "react";
import { FIREBASE_AUTH, db } from "../../firebaseconfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore"; 



const SignupScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const auth = FIREBASE_AUTH;
    
    

    const signUp = async () => {
        setLoading(true);
        try {
            const response = await createUserWithEmailAndPassword(auth, email, password);
            const user = response.user;

             console.log(response);
            const docRef = await addDoc(collection(db, "users"), {
              email: email.toLowerCase().trim(),
              uid: user.uid,
              firstname: firstName.trim(),
              lastName: lastName.trim(),
            });
            alert('Check your email!');
             } catch (error) {
                console.log(error);
                alert('Sign up failed' + error.message);
             } finally {
                setLoading(false)
             }
    }
return  (
    <View style={styles.container}>
        <KeyboardAvoidingView behavior="padding">
    <TextInput value={firstName} style={styles.input} placeholder="First name" autoCapitalize="none" onChangeText={(text) => setFirstName(text)} />
    <TextInput value={lastName} style={styles.input} placeholder="Last name" autoCapitalize="none" onChangeText={(text) => setLastName(text)} />

    <TextInput value={email} style={styles.input} placeholder="Email" autoCapitalize="none" onChangeText={(text) => setEmail(text)} />
    <TextInput secureTextEntry={true} value={password} style={styles.input} placeholder="password" autoCapitalize="none" onChangeText={(text) => setPassword(text)} />

{ loading ? (
    <ActivityIndicator size="large" color="#0000ff" />
) : (
<>

<Button title="Create account" onPress={() => signUp() } />

</> 
)}
</KeyboardAvoidingView>
  </View>

);
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      marginHorizontal: 20,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
    },
    input: {
    marginVertical: 4,
      height: 50,
      borderColor: "gray",
      borderWidth: 1,
      borderRadius: 4,
      padding: 10,
    },
  });

export default SignupScreen;