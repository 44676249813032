import { View, Text, TextInput, Button, StyleSheet, ActivityIndicator, KeyboardAvoidingView, Pressable } from "react-native";
import React, { useState } from "react";
import { FIREBASE_AUTH } from "../../firebaseconfig";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";





const Login = ({navigation}) => {


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [eventcode, setEventcode] = useState('');
    const auth = FIREBASE_AUTH;

    const signIn = async () => {
        setLoading(true);
        try {
          if (eventcode === "Test2024") {
            const response = await signInWithEmailAndPassword(auth, email, password);
            console.log(response);
             } else { 
              alert('Invalid event code. Sign in failed');
             }}
             catch (error) {
                console.log(error);
                alert('Sign in failed. Please check your mail and password.');
             } finally {
                setLoading(false)
             }
    }

    const signUp = async () => {
        setLoading(true);
        try {
            const response = await createUserWithEmailAndPassword(auth, email, password);
            console.log(response);
            alert('Check your email!');
             } catch (error) {
                console.log(error);
                alert('Sign up failed');
             } finally {
                setLoading(false)
             }
    }
return  (
    <View style={styles.container}>
        <KeyboardAvoidingView behavior="padding">
    <TextInput value={email} style={styles.input} placeholder="Email" autoCapitalize="none" onChangeText={(text) => setEmail(text)} />
    <TextInput secureTextEntry={true} value={password} style={styles.input} placeholder="password" autoCapitalize="none" onChangeText={(text) => setPassword(text)} />
    <TextInput value={eventcode} style={styles.input} placeholder="Event code" autoCapitalize="none" onChangeText={(text) => setEventcode(text)} />

{ loading ? (
    <ActivityIndicator size="large" color="#0000ff" />
) : (
<>

<Button title="Login" onPress={() => signIn() } />

<Pressable onPress={() => navigation.navigate('SignupScreen')}>
              <Text style={{ color: 'blue', marginTop: 10 }}>
                Don't have an account? Click here
              </Text>
            </Pressable>



</> 
)}
</KeyboardAvoidingView>
  </View>

);
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      marginHorizontal: 20,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
    },
    input: {
    marginVertical: 4,
      height: 50,
      borderColor: "gray",
      borderWidth: 1,
      borderRadius: 4,
      padding: 10,
    },
  });

export default Login;