import React, { useState, useEffect } from "react";
import { View, Text, Button } from "react-native";
import { FIREBASE_AUTH, db } from "../../firebaseconfig";
import { collection, query, where, getDocs } from "firebase/firestore";

const Lists = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = FIREBASE_AUTH.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const q = query(collection(db, "users"), where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            setUserData(doc.data());
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <View>
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <>
          <Text>Hej {userData?.firstname || 'Unknown'}</Text>
          <Button title="Details" onPress={() => navigation.navigate("details")} />
          <Button title="Sign Out" onPress={() => FIREBASE_AUTH.signOut()} />        </>
      )}
    </View>
  );
};

export default Lists;
