// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkQiIwpKtb6-0x3bF0lvbEIOTn--Clr4U",
  authDomain: "demologinsystem-76d5a.firebaseapp.com",
  projectId: "demologinsystem-76d5a",
  storageBucket: "demologinsystem-76d5a.appspot.com",
  messagingSenderId: "513619383784",
  appId: "1:513619383784:web:e8d38a2c2a12e5b847405d",
  measurementId: "G-L31PWHP46R"
};

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const db = getFirestore();
